.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.login-form-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.login-title {
  margin-bottom: 10px;
  text-transform: uppercase;
}
.login-title-sub {
  margin-bottom: 20px;
}
.no-margin {
  margin: 0;
}

.login-form {
  margin-bottom: 15px;
}

.login-button {
  width: 100%;
  background: "#57d061";
}
