.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.root-content {
  /* background-image: url("/public/images/background.jpg");
  background-position: center;
  background-size: cover; */
  margin: 0px;
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.root-content-header {
  background: #58d062;
}

.root-content-body {
  border-top: 1px solid #91f3a0;
  background-image: url("/public/images/background.jpg");
  background-position: center;
  background-size: cover;
}

.custom-btn:hover {
  color: #fff !important;
  background: linear-gradient(to bottom, #ffcc00, #ff5722) !important;
}

.custom-modal .modal-dialog {
  margin: auto; /* This centers the modal horizontally */
  display: flex;
  align-items: center; /* This centers the modal vertically */
  justify-content: center;
}

@media (max-width: 768px) {
  /* Media query for mobile screens */
  .custom-modal .modal-dialog {
    max-width: 90%; /* Set the width to 90% on mobile */
  }
}

@media (max-width: 768px) {
  .root-content-body {
    background-position: left;
  }
  .mobile-container .container {
    --bs-gutter-x: 0.5rem !important;
    margin-top: 0 !important;
  }
  .mobile-container {
    margin-top: 0 !important;
  }
  .mobile-container .row {
    --bs-gutter-x: 0.5rem !important;
  }
}

.admin-content {
  background: #f2f7f8;
  margin: 0px;
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  flex-grow: 1;
  min-height: calc(100vh - 60px);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
