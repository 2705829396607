.diamond {
  position: relative;
  overflow: hidden; /* Ẩn các phần bên ngoài */
  transform: skew(-10deg); /* Xuyên lệch hình bình hành */
  background-color: #f0f0f0; /* Màu nền hình bình hành */
  border-radius: 15px;
  max-width: 480px !important;
}
.image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Đảm bảo ảnh phủ toàn bộ */
  transform: skew(10deg);
}

.carousel .carousel-control-prev {
  justify-content: flex-start;
  left: 25px;
}
.carousel .carousel-control-prev .carousel-control-prev-icon {
  font-weight: bold;
  font-size: 1.3rem !important;
}
.carousel .carousel-control-next {
  justify-content: flex-end;
  font-weight: bold;
  right: 25px;
  font-size: 1.3rem !important;
}
.carousel .carousel-control-next-icon {
  font-size: 1.2rem !important;
}
.carousel .carousel-control-prev-icon {
  font-size: 1.2rem !important;
}
.carousel .carousel-indicators {
  display: none;
}
