.nav .nav-link {
  padding: 15px;
  color: #000;
  font-size: 16px;
  font-weight: bold;
}

.nav .nav-link:hover {
  background-color: #f8f9fa;
}

.nav .active-link,
.nav .active-link:focus {
  background-color: #26c6da;
  border-radius: 0.3rem;
  color: #fff;
}
