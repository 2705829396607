.pagination {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.pagination button {
  margin: 0 2px;
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button.active {
  background-color: #0056b3;
}

.pagination select {
  margin-left: 2px;
}

.show-per-page {
  display: flex;
  align-items: center;
}

.wrap-show-page {
  display: flex;
  padding: 10px 0;
}
