.wrap-information-bank {
  display: flex;
  background-image: url("/public/images/background.jpg");
  background-position: center;
  background-size: cover;
  padding: 20px 10px 10px 10px;
  border-radius: 5px;
}
.information-bank {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}
.wrap-user-bank-info {
  background-image: url("/public/images/background.jpg");
  background-position: center;
  background-size: cover;
}
.modal-footer {
  border-top: none !important;
}
