/* ContactFooter.css */
.contact-footer {
  position: fixed;
  right: 10px; /* Distance from the right edge */
  bottom: 10px; /* Distance from the bottom edge */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px; /* Space between buttons */
}

.circle-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px; /* Width of the circle */
  height: 65px; /* Height of the circle */
  border-radius: 50%; /* Makes it circular */
  background-color: #146c43; /* Background color */
  color: white; /* Icon color */
  text-decoration: none; /* Remove underline */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Optional: shadow for depth */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.contact-footer-mobile .circle-button {
  width: 45px; /* Width of the circle */
  height: 45px; /* Height of the circle */
}

.circle-button:hover {
  background-color: #ec6e23; /* Darker color on hover */
}

.circle-button:hover .contact-image {
  transform: scale(1.1);
}

.contact-image {
  transition: transform 0.3s ease;
}

.payment-button {
  cursor: pointer;
  display: flex;
  background-color: #ec6e23;
  border-radius: 25px;
  border: 1.5px solid #fff;
  color: #fff;
  height: 40px;
  align-items: center;
  padding: 0 10px;
  text-decoration: none;
  font-weight: 600;
}
