.navbar {
  background: transparent;
  height: 60px;
}
.navbar-expand-sm .navbar-nav .nav-link {
  margin-right: 10px;
  font-weight: bold;
  text-transform: uppercase;
}
.offcanvas-body .navbar-nav .nav-link {
  color: #fff;
  margin-right: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
}
.navbar-expand-sm .navbar-nav .leftNav .nav-link:focus,
.navbar-expand-sm .navbar-nav .leftNav .nav-link:hover {
  color: #fff;
  background-color: #07403f;
  border-radius: 5px;
}

.mobile-nav .navbar-nav .leftNav .nav-link:focus,
.mobile-nav .navbar-nav .leftNav .nav-link:hover {
  color: #fff;
  background-color: #ec6e23;
  border-radius: 5px;
}

.navbar-brand {
  margin-right: 10px;
  text-transform: uppercase;
}

.offcanvas-start.show {
  width: 75% !important;
  background-color: #07403f;
}

.mobile-nav .custom-nav {
  flex-direction: column !important;
}

.wrap_login {
  display: flex;
  background-color: #ec6e23;
  border-radius: 25px;
  border: 1.5px solid #fff;
  height: 40px;
  align-items: center;
  padding: 0 10px;
}

.wrap_login .nav-link {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
}
.wrap_login .nav-link:hover {
  color: #fff;
}

.mobile-nav .navbar {
  background: transparent;
  border-bottom: 1px solid #58d062;
  height: 60px;
}

.mobile-nav .navbar-nav .leftNav .nav-link {
  padding-left: 10px;
}

.mobile-nav .wrap_login {
  padding-left: 10px;
}
