.content-course {
  padding: 0;
}

.title {
  font-size: 16px;
  color: #ff7414;
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  font-weight: 500;
}

.detail {
  padding: 20px 0;
}

.wrap-content-header-mobile {
  padding: 10px 0;
}

.wrap-action {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.title-course {
  margin-left: 10px;
  color: #000;
  font-weight: bold;
}
.title-sub-course {
  margin-left: 10px;
}
.accordion-button {
  background-color: #f4f4f4 !important;
}
.wrap-content-header-mobile .accordion-button {
  padding: 8px;
}
.wrap-content-header-mobile .accordion-body {
  padding: 5px;
}
.wrap-content-header-mobile .title-sub-course {
  font-size: 14px;
}

.accordion-button::after {
  content: "" !important;
  display: none;
}

.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  color: #212529;
}

.wrap-content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.custom-btn:hover {
  color: #fff !important;
  background: linear-gradient(to bottom, #ffcc00, #ff5722) !important;
}
